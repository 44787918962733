import React, { useState } from "react";
import { useSelector } from "react-redux";
import Collapsible from "../../UI/Collapsible/Collapsible";
import { variables } from "../../../variables/variables";
import "./CheckoutSummary.scss";

const CheckoutSummary = ({ orderTotal, discountCode }) => {
  const displayWidth = useSelector((state) => state.layout.displayWidth);
  console.log(discountCode);
  console.log(orderTotal);
  const isMobile = displayWidth < variables.breakpoints.sml;
  const {
    total,
    subtotal,
    processing,
    shipping,
    totalAfterDiscount,
    discountApplied,
  } = orderTotal;

  return isMobile ? (
    <MobileCheckoutSummary
      total={total}
      subtotal={subtotal}
      processing={processing}
      shipping={shipping}
      discountCode={discountCode}
      totalAfterDiscount={totalAfterDiscount}
      discountApplied={discountApplied}
    />
  ) : (
    <DesktopCheckoutSummary
      total={total}
      subtotal={subtotal}
      processing={processing}
      shipping={shipping}
      discountCode={discountCode}
      totalAfterDiscount={totalAfterDiscount}
      discountApplied={discountApplied}
    />
  );
};

const MobileCheckoutSummary = ({
  total,
  subtotal,
  processing,
  shipping,
  discountCode,
  totalAfterDiscount,
  discountApplied,
}) => {
  const [orderDetailsOpen, setOrderDetailsOpen] = useState(false);

  const handleToggleDetails = () => {
    setOrderDetailsOpen(!orderDetailsOpen);
  };
  return (
    <div className="checkout-summary">
      <Collapsible isExpanded={orderDetailsOpen} id="checkout-summary-details">
        <div className="checkout-summary__order-details">
          <div className="checkout-summary__field">
            <p className="checkout-summary__label">Subtotal:</p>
            <p className="checkout-summary__value">
              {handleDisplayTotal(subtotal)}
            </p>
          </div>
          {discountCode && discountCode.discountCategory === "promo-code" && (
            <div className="checkout-summary__field">
              <p className="checkout-summary__label">{`Promo code (${discountCode.discountCode}):`}</p>
              <p className="checkout-summary__value checkout-summary__value--addon">
                {typeof discountApplied === "number" &&
                  `- ${handleDisplayTotal(discountApplied)}`}
              </p>
            </div>
          )}
          <div className="checkout-summary__field">
            <p className="checkout-summary__label">Processing fees:</p>
            <p className="checkout-summary__value checkout-summary__value--addon">
              + {handleDisplayTotal(processing)}
            </p>
          </div>
          <div className="checkout-summary__field">
            <p className="checkout-summary__label">Delivery:</p>
            <p className="checkout-summary__value checkout-summary__value--addon">
              {discountCode && discountCode.isFreeShipping
                ? "FREE"
                : `+ ${handleDisplayTotal(shipping)}`}
            </p>
          </div>
          {discountCode && discountCode.discountCategory === "gift-card" && (
            <div className="checkout-summary__field">
              <p className="checkout-summary__label">{`Digital gift card (${discountCode.discountCode}):`}</p>
              <p className="checkout-summary__value checkout-summary__value--addon">
                - {handleDisplayTotal(discountApplied)}
              </p>
            </div>
          )}
          {discountCode && discountCode.currentBalance >= total && (
            <div className="checkout-summary__field">
              <p className="checkout-summary__label">
                Minimum processing amount:
              </p>
              <p className="checkout-summary__value checkout-summary__value--addon">
                + {handleDisplayTotal(50)}
              </p>
            </div>
          )}
        </div>
      </Collapsible>
      <div className="checkout-summary__mobile-panel">
        <button
          className={`checkout-summary__review-toggle ${
            orderDetailsOpen
              ? "checkout-summary__review-toggle--open"
              : "checkout-summary__review-toggle--closed"
          }`}
          onClick={() => handleToggleDetails()}
        >
          {orderDetailsOpen ? "Hide order details" : "Show order details"}
        </button>
        <div className="checkout-summary__total">
          <p className="checkout-summary__total-label">Total:</p>
          <span className="checkout-summary__total-divider"></span>
          <p className="checkout-summary__total-value">
            {handleDisplayTotal(totalAfterDiscount || total)}
          </p>
        </div>
      </div>
    </div>
  );
};

const DesktopCheckoutSummary = ({
  total,
  subtotal,
  processing,
  shipping,
  discountCode,
  totalAfterDiscount,
  discountApplied,
}) => {
  return (
    <div className="checkout-summary">
      <div className="checkout-summary__order-details">
        <div className="checkout-summary__field">
          <p className="checkout-summary__label">Subtotal:</p>
          <p className="checkout-summary__value">
            {handleDisplayTotal(subtotal)}
          </p>
        </div>
        {discountCode && discountCode.discountCategory === "promo-code" && (
          <div className="checkout-summary__field">
            <p className="checkout-summary__label">{`Promo code (${discountCode.discountCode}):`}</p>
            <p className="checkout-summary__value checkout-summary__value--addon">
              {typeof discountApplied === "number" &&
                `- ${handleDisplayTotal(discountApplied)}`}
            </p>
          </div>
        )}
        <div className="checkout-summary__field">
          <p className="checkout-summary__label">Processing fees:</p>
          <p className="checkout-summary__value checkout-summary__value--addon">
            + {handleDisplayTotal(processing)}
          </p>
        </div>
        <div className="checkout-summary__field">
          <p className="checkout-summary__label">Delivery:</p>
          <p className="checkout-summary__value checkout-summary__value--addon">
            {discountCode && discountCode.isFreeShipping
              ? "FREE"
              : `+ ${handleDisplayTotal(shipping)}`}
          </p>
        </div>
        {discountCode && discountCode.discountCategory === "gift-card" && (
          <div className="checkout-summary__field">
            <p className="checkout-summary__label">{`Digital gift card (${discountCode.discountCode}):`}</p>
            <p className="checkout-summary__value checkout-summary__value--addon">
              - {handleDisplayTotal(discountApplied)}
            </p>
          </div>
        )}
        {discountCode && discountCode.currentBalance >= total && (
          <div className="checkout-summary__field">
            <p className="checkout-summary__label">
              Minimum processing amount:
            </p>
            <p className="checkout-summary__value checkout-summary__value--addon">
              + {handleDisplayTotal(50)}
            </p>
          </div>
        )}
      </div>
      <div className="checkout-summary__total">
        <p className="checkout-summary__total-label">Total:</p>
        <span className="checkout-summary__total-divider"></span>
        <p className="checkout-summary__total-value">
          {handleDisplayTotal(totalAfterDiscount || total)}
        </p>
      </div>
    </div>
  );
};

const handleDisplayTotal = (valueInCents) => {
  var valueInDollars = valueInCents / 100;
  valueInDollars = valueInDollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return valueInDollars;
};

export default CheckoutSummary;
